import $ from 'jquery';
import Swal from 'sweetalert2'
import axios from 'axios'
	
$(document).ready(function(){
    var timerInterval = 0;
    var hostFrontend = window.location.protocol + "//" + window.location.host;
    var hostBackend = window.location.protocol + "//" + window.location.host.replace(":5000", "");
    //var hostBackend = 'http://45.56.127.15';
	$(this).on('keypress',function(e) {
		if(e.which == 13) {
			const user = $("#username").val();
			const pass = $("#password").val();

            let data = {
                username: user,
                password: pass
            }
            
            axios.post(hostBackend+ ':4000/login', data)
            .then(function (response) {
                if(response) {

                    if(response.data == "someonelogin") {
                        Swal.fire({
                            title: 'Someone already logged in',
                            text: 'Looks like the account you are trying to login is already active',
                            confirmButtonText: 'Go back',
                            icon: 'error',
                          }).then((result) => {
                            window.location = "/";
                          })
                    }else if(response.data == "notconfirmed") {
                        Swal.fire({
                            title: 'Your email is not confirmed',
                            text: 'We have sent you a confirmation email please make sure to confirm it first.',
                            confirmButtonText: 'Okay',
                            icon: 'error',
                          }).then((result) => {
                            window.location = "/";
                          })
                    }else {
                        localStorage.setItem("tokenAuth", response.data.token);
                        Swal.fire({
                            title: 'Login successful',
                            html: 'Auto-closing in <strong></strong> seconds',
                            confirmButtonText: 'Proceed',
                            icon: 'success',
                            timer: 3000,
                            didOpen: () => {
                                const content = Swal.getHtmlContainer()
                                const $ = content.querySelector.bind(content)
                            
                                Swal.showLoading()
                            
                            
                                timerInterval = setInterval(() => {
                                  Swal.getHtmlContainer().querySelector('strong')
                                    .textContent = (Swal.getTimerLeft() / 1000)
                                      .toFixed(0)
                                }, 100)
                              },
                              willClose: () => {
                                clearInterval(timerInterval)
                              }
                        }).then((result) => {
                            window.location = "app.html"
                        })
                    }
					
				}else {
					Swal.fire({
                        title: 'Wrong Access!',
                        text: 'Invalid username and password please try again',
                        confirmButtonText: 'Try again',
                        icon: 'error',
                    }).then((result) => {
                        window.location.reload();
                    })
				}
            })
            .catch(function (error) {
                Swal.fire({
                    title: 'Wrong Access!',
                    text: 'Invalid username and password please try again',
                    confirmButtonText: 'Try again',
                    icon: 'error',
                }).then((result) => {
                    window.location.reload();
                })
            });

		}
	});

	$("#loginBtn").click(function(){

		const user = $("#username").val();
        const pass = $("#password").val();

        let data = {
            username: user,
            password: pass
        }

        axios.post(hostBackend+ ':4000/login', data)
        .then(function (response) {
            if(response) {
                localStorage.setItem("tokenAuth", response.data.token);
                Swal.fire({
                    title: 'Login successful',
                    html: 'Auto-closing in <strong></strong> seconds',
                    confirmButtonText: 'Proceed',
                    icon: 'success',
                    timer: 3000,
                    didOpen: () => {
                        const content = Swal.getHtmlContainer()
                        const $ = content.querySelector.bind(content)
                    
                        Swal.showLoading()
                    
                    
                        timerInterval = setInterval(() => {
                          Swal.getHtmlContainer().querySelector('strong')
                            .textContent = (Swal.getTimerLeft() / 1000)
                              .toFixed(0)
                        }, 100)
                      },
                      willClose: () => {
                        clearInterval(timerInterval)
                      }
                }).then((result) => {
                    window.location = "app.html"
                })
            }else {
                Swal.fire({
                    title: 'Wrong Access!',
                    text: 'Invalid username and password please try again',
                    confirmButtonText: 'Try again',
                    icon: 'error',
                }).then((result) => {
                    window.location.reload();
                })
            }
        })
        .catch(function (error) {
            Swal.fire({
                title: 'Wrong Access!',
                text: 'Invalid username and password please try again',
                confirmButtonText: 'Try again',
                icon: 'error',
            }).then((result) => {
                window.location.reload();
            })
        });
	});
});